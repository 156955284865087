













import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'navLayout'
})
export default class InsNavLayout extends Vue {
  @Prop() private navData!: object;
  @Prop({ default: 1 }) private level!: number;

  toUrl (n) {
    if (!n.IsNewWin && n.Url) {
      window.location.href = n.Url;
    } else if (n.IsNewWin && n.Url) {
      window.open(n.Url);
    }
  }

  To (n) {
    return n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/cms/content/' + n.Value.Id : n.Type === 3 ? '/regnpay/form/' + n.Value.Id : '/';
  }
}
