import { render, staticRenderFns } from "./InsNavLayout.vue?vue&type=template&id=0bec6a56&scoped=true&"
import script from "./InsNavLayout.vue?vue&type=script&lang=ts&"
export * from "./InsNavLayout.vue?vue&type=script&lang=ts&"
import style0 from "./InsNavLayout.vue?vue&type=style&index=0&id=0bec6a56&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bec6a56",
  null
  
)

export default component.exports